import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: () =>
            import ('@/layout/index.vue'),
        children: [{
                path: '',
                name: 'Home',
                meta: {
                    title: '首页'
                },
                component: () =>
                    import ('@/views/Home/index.vue')
            },
            {
                path: 'product',
                name: 'Product',
                meta: {
                    title: '产品中心'
                },
                component: () =>
                    import ('@/views/Product/index.vue')
            },
            {
                path: 'product/detail/:id',
                name: 'ProductDetail',
                meta: {
                    title: '产品详情'
                },
                component: () =>
                    import ('@/views/Product/detail.vue')
            },
            {
                path: 'about/:type',
                name: 'About',
                meta: {
                    title: '关于棠溪'
                },
                component: () =>
                    import ('@/views/About/index.vue')
            },
            {
                path: 'swordCulture',
                name: 'SwordCulture',
                meta: {
                    title: '剑文化',
                    keepAlive: true
                },
                component: () =>
                    import ('@/views/SwordCulture/index.vue')
            },
            {
                path: 'swordCulture/detail/:id',
                name: 'SwordCultureDetail',
                meta: {
                    title: '文章详情'
                },
                component: () =>
                    import ('@/views/SwordCulture/detail.vue')
            },
            {
                path: 'media',
                name: 'Media',
                meta: {
                    title: '媒体聚焦'
                },
                component: () =>
                    import ('@/views/Media/index.vue')
            },
            {
                path: 'media/detail/:id',
                name: 'MediaDetail',
                meta: {
                    title: '文章详情'
                },
                component: () =>
                    import ('@/views/SwordCulture/detail.vue')
            },
            {
                path: 'spzx/detail/:video/:title/:id',
                name: 'SpzxDetail',
                meta: {
                    title: '视频详情'
                },
                component: () =>
                    import ('@/views/Media/videoDetail.vue')
            },
            {
                path: 'wccp/detail/:video/:title/:id',
                name: 'WccpDetail',
                meta: {
                    title: '文创产品'
                },
                component: () =>
                    import ('@/views/Media/videoDetail.vue')
            },
            {
                path: 'media/wccp',
                name: 'MediaWccp',
                meta: {
                    title: '产品'
                },
                component: () =>
                    import ('@/views/Media/wccp.vue')
            },
            {
                path: 'media/spzx',
                name: 'MediaSpzx',
                meta: {
                    title: '视频'
                },
                component: () =>
                    import ('@/views/Media/spzx.vue')
            },
            {
                path: 'media/wenhua',
                name: 'MediaWenhua',
                meta: {
                    title: '文化'
                },
                component: () =>
                    import ('@/views/Media/wenhua.vue')
            },
            {
                path: 'media/huodong',
                name: 'MediaHuodong',
                meta: {
                    title: '活动'
                },
                component: () =>
                    import ('@/views/Media/huodong.vue')
            },
            {
                path: 'media/peixun',
                name: 'MediaPeixun',
                meta: {
                    title: '培训'
                },
                component: () =>
                    import ('@/views/Media/peixun.vue')
            },
            {
                path: 'contact',
                name: 'Contact',
                meta: {
                    title: '联系我们'
                },
                component: () =>
                    import ('@/views/Contact/index.vue')
            },
            {
                path: 'order',
                name: 'Order',
                meta: {
                    title: '订购流程'
                },
                component: () =>
                    import ('@/views/Order/index.vue')
            },
            {
                path: 'wealth',
                name: 'Wealth',
                meta: {
                    title: '财富机会'
                },
                component: () =>
                    import ('@/views/Wealth/index.vue')
            },
            {
                path: 'collection',
                name: 'Collection',
                meta: {
                    title: '我的收藏',
                    auth: true
                },
                component: () =>
                    import ('@/views/User/collection.vue')
            }
        ]
    },

]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        // return 期望滚动到哪个的位置
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach(async(to, from, next) => {

    if (to.meta.auth && !store.getters.token) {
        next('/')
        return
    }
    document.title = to.meta.title + '-棠溪'
    next()
})

export default router