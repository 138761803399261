import request from '@/utils/request'

export const goLogin = (data) => {
    return request({
        url: '/api/user/login',
        method: 'post',
        data
    })
}

export const goRegister = (data) => {
    return request({
        url: '/api/user/register',
        method: 'post',
        data
    })
}

// export const getInfo = (token) => {
//   return request({
//     url: '/user/info',
//     method: 'get'
//   })
// }