import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
        baseURL: 'https://tx.tangxibaojian.com/addons/tangxi', // url = base url + request url
        // withCredentials: true, // send cookies when cross-domain requests
        timeout: 20000 // request timeout
    })
    // request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        if (store.getters.token) {
            config.headers['token'] = getToken()
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error.response)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        return response
    },
    error => {
        return Promise.reject(error)
    }
)

export default service