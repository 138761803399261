import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/reset.css'
import animate from 'animate.css'
Vue.config.productionTip = false
    // Vue.prototype.$mTabBgStyle = {
    //   background: 'url(' + require('@/assets/m_tab_bg.png') + ')' + 'no-repeat',
    //   backgroundSize: '100% 100%'
    // }
    // Vue.prototype.$mTabBgActiveStyle = {
    //   background: 'url(' + require('@/assets/m_tab_bg_active.png') + ')' + 'no-repeat',
    //   backgroundSize: '100% 100%'
    // }
    router.beforeEach((to,from,next)=>{
        console.log(to,from);
        document.title = "棠溪宝剑|棠溪|河南棠溪宝剑有限公司";
        console.log('1',document);
        document.querySelector('meta[name="keywords"]').setAttribute('content', "棠溪宝剑|棠溪|河南棠溪宝剑有限公司");
        document.querySelector('meta[name="description"]').setAttribute('content', "棠溪宝剑|棠溪|河南棠溪宝剑有限公司");
        console.log('2',document);
        next()
    })

import Message from 'vue-m-message'
import 'vue-m-message/dist/index.css'

Vue.use(Message) // will mount `Vue.prototype.$message`
Vue.use(ElementUI)
Vue.use(animate)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')