import { goLogin } from '@/api/user'
import { getToken, setToken } from '@/utils/auth'

const state = {
  token: getToken()
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      // login({ username: username.trim(), password: password }).then(response => {
      //   const { data } = response
      //   commit('SET_TOKEN', data.token)
      //   setToken(data.token)
      //   resolve()
      // }).catch(error => {
      //   reject(error)
      // })
      goLogin(userInfo).then(res => {
        if (res.data.code) {
          commit('SET_TOKEN', res.data.data.userinfo.token)
          setToken(res.data.data.userinfo.token)
          resolve(res.data.msg)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  registerLogin({ commit }, token) {
    commit('SET_TOKEN', token)
    setToken(token)
  }

  // get user info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     getInfo().then(response => {
  //       const { data } = response
  //       if (!data) {
  //         reject('验证失败，请重新登录')
  //       }
  //       const { authority, name, avatar } = data
  //       // roles must be a non-empty array
  //       // if (!roles || roles.length <= 0) {
  //       //   reject('获取信息: 角色必须是非空数组！')
  //       // }
  //       resolve(data)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
